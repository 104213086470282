<template>
<div>
  <b-card class="card-custom gutter-b">
    <template #header>
      <b-row class="w-100 d-flex align-items-center" no-gutters>
        <b-col xs=6>
          <h5 class="m-0">Hotel Info</h5>
        </b-col>
        <b-col xs=6 class="text-right">
          <b-button variant="primary" v-b-modal.cm-modal-form-hotel-info>Add New</b-button>
        </b-col>
      </b-row>
    </template>
    <b-row>
      <b-col sm=12 class="mb-5">
        <b-row no-gutters class="justify-content-between">
          <b-col sm=12 md=3 class="mb-3 mb-sm-0">
            <b-input-group prepend="Search">
              <b-form-input v-model="pagingHotelInfo.search" type="search" id="filterInput" placeholder="Type to Search"></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!pagingHotelInfo.search" @click="pagingHotelInfo.search = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col sm=12 md=2 class="d-flex justify-content-end">
            <b-input-group prepend="Per Page">
              <b-select :options="optHotelInfo.perPage" v-model="pagingHotelInfo.perPage" />
            </b-input-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm=12 class="mb-5">
        <b-overlay :show="tableHotelInfo.loading">
          <b-table show-empty small :fields="tableHotelInfo.fields" :items="tableHotelInfo.items" :current-page="pagingHotelInfo.page" :sort-by.sync="pagingHotelInfo.sortBy" :sort-desc.sync="pagingHotelInfo.sortDesc" :sort-direction="pagingHotelInfo.sortDirection" bordered striped hover responsive>
            <template #cell(no)="row">
              {{ (pagingHotelInfo.perPage * (pagingHotelInfo.page-1)) + row.index + 1 }}
            </template>
            <template #cell(content)="row">
              <div v-html="row.item.content" class="px-5" />
            </template>
            <template #cell(action)="row">
              <b-button size="sm" variant="danger" @click="doDeleteHotelInfo(row.item.id,false)" title="Delete">
                <b-icon icon="trash" />
              </b-button>
              <b-button size="sm" variant="info" @click="doUpdateHotelInfo(row.item.id)" class="ml-0 ml-sm-3 mt-2 mt-sm-0" title="Update">
                <b-icon icon="pencil" />
              </b-button>
            </template>
          </b-table>
        </b-overlay>
      </b-col>
      <b-col sm=12>
        <b-row no-gutters class="w-100">
          <b-col sm=12 md=6 class="d-flex justify-content-center justify-content-sm-start mb-3 mb-sm-0">
            Showing {{pagingHotelInfo.perPage}} data out of {{tableHotelInfo.totalRows}}
          </b-col>
          <b-col sm=12 md=6 class="d-flex justify-content-center justify-content-sm-end">
            <b-pagination v-model="pagingHotelInfo.page" :total-rows="tableHotelInfo.totalRows" :per-page="pagingHotelInfo.perPage"></b-pagination>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
  <b-modal title="Hotel Info Form" id="cm-modal-form-hotel-info" hide-footer size="md" centered>
    <form @submit.prevent="doSaveHotelInfo()">
      <b-row>
        <b-col sm=12>
          <b-form-group label="Judul" :state="!$v.formHotelInfo.data.title.$error">
            <b-input v-model="formHotelInfo.data.title" />
          </b-form-group>
          <b-form-group label="Isi" :state="!$v.formHotelInfo.data.content.$error">
            <b-textarea no-resize rows="5" v-model="formHotelInfo.data.content" />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="d-flex py-3 border-top justify-content-end">
        <b-button variant="light" @click="$bvModal.hide('cm-modal-form-hotel-info')">Close</b-button>
        <b-button type="submit" :disabled="formHotelInfo.loading" variant="primary" class="ml-3">
          <b-spinner small v-show="formHotelInfo.loading" /> Save
        </b-button>
      </div>
    </form>
  </b-modal>
  <b-modal header-bg-variant="warning" centered body-bg-variant="warning" id="cm-modal-delete-hotel-info" size="sm" hide-footer hide-header>
    <div class="text-center">
      <h1 class="text-light">Warning</h1>
      <p class="text-center mb-3 text-light">Are You Sure to Delete this Data ?</p>
      <div class="d-flex justify-content-center">
        <b-button variant="light" @click="$bvModal.hide('cm-modal-delete-hotel-info')">Cancel</b-button>
        <b-button variant="danger" @click="doDeleteHotelInfo(formHotelInfo.deleteId,true)" class="ml-3">Delete!!!</b-button>
      </div>
    </div>
  </b-modal>
</div>
</template>

<script>
import {
  validationMixin
} from 'vuelidate'
import {
  required
} from 'vuelidate/lib/validators'
import {
  baseUrl
} from '@/core/config/baseUrl'
import {
  icons
} from '@/core/config/icons'
export default {
  data() {
    return {
      formHotelInfo: {
        loading: false,
        data: {
          id: null,
          image: null,
          title: null,
          content: null,
          id_parent: null,
          type: 'HOTEL INFO ITEM',
        },
        deleteId: null
      },
      tableHotelInfo: {
        items: [],
        fields: [{
            key: 'no',
            label: 'No',
            sortable: false,
            class: 'w-5 text-center'
          },
          {
            key: 'title',
            label: 'Judul',
            sortable: true,
            thClass: 'text-center',
            tdClass: ''
          },
          {
            key: 'content',
            label: 'Isi',
            thClass: 'text-center',
            tdClass: ''
          },
          {
            key: 'action',
            label: 'Action',
            sortable: false,
            class: 'text-center w-10'
          }
        ],
        totalRows: 0,
        loading: false
      },
      pagingHotelInfo: {
        page: 1,
        perPage: 10,
        search: '',
        sortBy: 'post.title',
        sortDesc: false,
        sortDirection: 'asc',
        type: 'HOTEL INFO ITEM'
      },
      optHotelInfo: {
        perPage: [10, 20, 30, 40, 50, 100, 200],
        icons: icons
      },
    }
  },
  mixins: [validationMixin],
  validations: {
    formHotelInfo: {
      data: {
        title: {
          required
        },
        content: {
          required
        },
      }
    }
  },
  methods: {
    doLoadHotelInfo() {
      this.tableHotelInfo.loading = true
      let payload = this.pagingHotelInfo
      payload.sortDirection = this.pagingHotelInfo.sortDesc ? 'asc' : 'desc'
      let url = baseUrl + `/api/post`
      this.fetchData(url, 'POST', payload)
        .then(res => {
          if (res.status) {
            this.tableHotelInfo.items = res.data
            this.tableHotelInfo.totalRows = res.total
          } else {
            this.notif('Failed', res.msg, 'danger')
          }
          this.tableHotelInfo.loading = false
        })
        .catch(e => {
          this.tableHotelInfo.loading = false
          console.log(e)
          this.notif('Failed', 'List:Internal server error', 'danger')
        });
    },
    doUpdateHotelInfo(id) {
      let url = baseUrl + `/api/post/get`
      let data = {
        id: id
      }
      this.fetchData(url, 'POST', data)
        .then(res => {
          let resData = res.data
          this.formHotelInfo.data.id = resData.id
          this.formHotelInfo.data.image = resData.image
          this.formHotelInfo.data.title = resData.title
          this.formHotelInfo.data.content = resData.content
          this.formHotelInfo.data.id_parent = resData.id_parent
          this.formHotelInfo.data.type = 'HOTEL INFO ITEM'
          resData.child.map((x) => {
            switch (x.type) {
              case 'BTN HOTEL INFO ITEM':
                this.formHotelInfo.data.child.btn = x
                break;
            }
          })
          this.$bvModal.show('cm-modal-form-hotel-info')
        })
        .catch(e => {
          console.log(e)
          this.notif('Failed', 'Get:Internal server error', 'danger')
        });
    },
    doSaveHotelInfo() {
      this.formHotelInfo.loading = true
      this.$v.formHotelInfo.data.$touch()
      if (this.$v.formHotelInfo.data.$invalid) {
        this.formHotelInfo.loading = false
        this.notif('Warning', 'Please complete required field', 'warning')
      } else {
        let url = baseUrl + '/api/post/create'
        if (this.formHotelInfo.data.id)
          url = baseUrl + '/api/post/update'
        this.fetchData(url, 'POST', this.formHotelInfo.data)
          .then(res => {
            if (res.status) {
              this.notif('Success', res.msg, 'success')
              this.clearFormHotelInfo()
              this.doLoadHotelInfo()
            } else {
              this.notif('Failed', res.msg, 'danger')
            }
            this.formHotelInfo.loading = false
          })
          .catch(e => {
            this.formHotelInfo.loading = false
            this.notif('Failed', 'Save:Internal server error', 'danger')
          });
      }
    },
    doDeleteHotelInfo(id, promt) {
      if (promt) {
        let url = baseUrl + `/api/post/delete`
        let data = {
          id: id
        }
        this.fetchData(url, 'POST', data)
          .then(res => {
            if (res.status) {
              this.notif('Success', res.msg, 'success')
              this.doLoadHotelInfo()
              this.$bvModal.hide('cm-modal-delete-hotel-info')
            } else {
              this.notif('Error', res.msg, 'danger')
              this.doLoadHotelInfo()
              this.$bvModal.hide('cm-modal-delete-hotel-info')
            }
          })
          .catch(e => {
            console.log(e)
            this.notif('Failed', 'Delete:Internal server error', 'danger')
          });
      } else {
        this.formHotelInfo.deleteId = id
        this.$bvModal.show('cm-modal-delete-hotel-info')
      }
    },
    fetchData(url, type, data) {
      let params = {
        method: type,
        headers: {
          'Content-Type': 'application/json',
        }
      }
      if (data)
        params.body = JSON.stringify(data)
      return fetch(
          url, params
        )
        .then(response => response.json())
    },
    previewImageHotelInfo(event, target) {
      let theImg = null
      let vm = this
      const inp = this.$refs.cm_form_img_hotel_info
      if (target == 'MAIN') {
        theImg = event.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(theImg);
        reader.onload = function () {
          vm.formHotelInfo.data.image = reader.result
          inp.type = 'text';
          inp.type = 'file';
        };
        reader.onerror = function () {
          vm.formHotelInfo.data.image = null
          inp.type = 'text';
          inp.type = 'file';
        };
      }
    },
    deleteImageTesti(idx) {
      let id = this.formHotelInfo.data.child.image[idx].id
      if (id > 0) {
        this.formHotelInfo.data.child.image[idx].deleted = true
      } else {
        this.formHotelInfo.data.child.image.splice(idx, 1);
      }
    },
    clearFormHotelInfo() {
      this.$v.formHotelInfo.data.$reset()
      this.formHotelInfo.data = {
        id: null,
        image: null,
        title: null,
        content: null,
        id_parent: null,
        type: 'HOTEL INFO ITEM',
      }
      this.formHotelInfo.deleteId = null
      this.$bvModal.hide('cm-modal-form-hotel-info')
    },
    notif(title, msg, type) {
      this.$bvToast.toast(msg, {
        title: title,
        autoHideDelay: 5000,
        variant: type,
        solid: true,
        toaster: 'b-toaster-bottom-left'
      })
    },
    thousand(num) {
      if (num) {
        let num_parts = num.toString().split(",");
        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return num_parts.join(",");
      } else {
        return 0
      }
    },
  },
  watch: {
    paging: {
      handler() {
        this.doLoadHotelInfo()
      },
      deep: true
    }
  },
  mounted() {
    this.doLoadHotelInfo()
    this.$root.$on('bv::modal::hide', () => {
      this.clearFormHotelInfo()
    })
  },
}
</script>

<style lang="scss">
.cm-delete-img {
  position: absolute;
  top: -10px;
  right: 0px;
  background: red;
  color: white;
  font-size: 20px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  transform: rotate(45deg);
  cursor: pointer;
}

.project-caption {
  padding: 10px;
  border: 1px dashed grey;

  h6,
  p {
    color: white;
    text-shadow: 1px 1px grey;
  }
}

.w-5 {
  width: 5%;
}

.w-10 {
  width: 10%;
}

.modal-dialog.modal-xxl {
  max-width: 90%;
}

.project-img {
  div[role="group"] {
    position: relative;
  }
}
</style>
