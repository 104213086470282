<template>
<div>
  <b-card class="card-custom gutter-b">
    <template #header>
      <b-row class="w-100 d-flex align-items-center" no-gutters>
        <b-col xs=6>
          <h5 class="m-0">Carousel</h5>
        </b-col>
        <b-col xs=6 class="text-right">
          <b-button variant="primary" v-b-modal.cm-modal-form-carousel>Add New</b-button>
        </b-col>
      </b-row>
    </template>
    <b-row>
      <b-col sm=12 class="mb-5">
        <b-row no-gutters class="justify-content-between">
          <b-col sm=12 md=3 class="mb-3 mb-sm-0">
            <b-input-group prepend="Search">
              <b-form-input v-model="pagingCarousel.search" type="search" id="filterInput" placeholder="Type to Search"></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!pagingCarousel.search" @click="pagingCarousel.search = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col sm=12 md=2 class="d-flex justify-content-end">
            <b-input-group prepend="Per Page">
              <b-select :options="optCarousel.perPage" v-model="pagingCarousel.perPage" />
            </b-input-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm=12 class="mb-5">
        <b-overlay :show="tableCarousel.loading">
          <b-table show-empty small :fields="tableCarousel.fields" :items="tableCarousel.items" :current-page="pagingCarousel.page" :sort-by.sync="pagingCarousel.sortBy" :sort-desc.sync="pagingCarousel.sortDesc" :sort-direction="pagingCarousel.sortDirection" bordered striped hover responsive>
            <template #cell(no)="row">
              {{ (pagingCarousel.perPage * (pagingCarousel.page-1)) + row.index + 1 }}
            </template>
            <template #cell(image)="row">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <b-img :src="row.item.image" thumbnail style="width:200px" v-show="row.item.image"/>
              </div>
            </template>
            <template #cell(title)="row">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <b-img :src="row.item.image" thumbnail style="width:200px" />
                <h5 class="mt-3">{{row.item.title}}</h5>
              </div>
            </template>
            <template #cell(content)="row">
              <div v-html="row.item.content" class="px-5" />
            </template>
            <template #cell(action)="row">
              <b-button size="sm" variant="danger" @click="doDeleteCarousel(row.item.id,false)" title="Delete">
                <b-icon icon="trash" />
              </b-button>
              <b-button size="sm" variant="info" @click="doUpdate(row.item.id)" class="ml-0 ml-sm-3 mt-2 mt-sm-0" title="Update">
                <b-icon icon="pencil" />
              </b-button>
            </template>
          </b-table>
        </b-overlay>
      </b-col>
      <b-col sm=12>
        <b-row no-gutters class="w-100">
          <b-col sm=12 md=6 class="d-flex justify-content-center justify-content-sm-start mb-3 mb-sm-0">
            Showing {{pagingCarousel.perPage}} data out of {{tableCarousel.totalRows}}
          </b-col>
          <b-col sm=12 md=6 class="d-flex justify-content-center justify-content-sm-end">
            <b-pagination v-model="pagingCarousel.page" :total-rows="tableCarousel.totalRows" :per-page="pagingCarousel.perPage"></b-pagination>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
  <b-modal title="Carousel Form" id="cm-modal-form-carousel" hide-footer size="xxl" centered>
    <form @submit.prevent="doSave()">
      <b-row>
        <b-col sm=6>
          <b-form-group label="Featured Image" description="Click to change/ add Image" class="project-img">
            <label for="cm_form_img_carousel" class="w-100" ref="previewImage">
              <b-img-lazy :src="(formCarousel.data.image) ? formCarousel.data.image : require('@/assets/images/default.png')" fluid class="w-100" />
              <input type="file" ref="cm_form_img_carousel" hidden id="cm_form_img_carousel" @change="previewImage($event,'MAIN')" accept="image/*">
            </label>
          </b-form-group>
        </b-col>
        <b-col sm=6>
          <b-form-group label="Title" :state="!$v.formCarousel.data.title.$error">
            <b-input v-model="formCarousel.data.title" />
          </b-form-group>
          <b-form-group label="Description" :state="!$v.formCarousel.data.content.$error">
            <b-textarea no-resize rows="5" v-model="formCarousel.data.content" />
          </b-form-group>
          <b-form-group label="Button Text" :state="!$v.formCarousel.data.child.btn.title.$error">
            <b-input v-model="formCarousel.data.child.btn.title" />
          </b-form-group>
          <b-form-group label="Button Link">
            <b-input v-model="formCarousel.data.child.btn.content" />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="d-flex py-3 border-top justify-content-end">
        <b-button variant="light" @click="$bvModal.hide('cm-modal-form-carousel')">Close</b-button>
        <b-button type="submit" :disabled="formCarousel.loading" variant="primary" class="ml-3">
          <b-spinner small v-show="formCarousel.loading" /> Save
        </b-button>
      </div>
    </form>
  </b-modal>
  <b-modal header-bg-variant="warning" centered body-bg-variant="warning" id="cm-modal-delete-carousel" size="sm" hide-footer hide-header>
    <div class="text-center">
      <h1 class="text-light">Warning</h1>
      <p class="text-center mb-3 text-light">Are You Sure to Delete this Data ?</p>
      <div class="d-flex justify-content-center">
        <b-button variant="light" @click="$bvModal.hide('cm-modal-delete-carousel')">Cancel</b-button>
        <b-button variant="danger" @click="doDeleteCarousel(formCarousel.deleteId,'yes')" class="ml-3" :data-delete="formCarousel.deleteId">Delete!!!</b-button>
      </div>
    </div>
  </b-modal>
</div>
</template>

<script>
import {
  validationMixin
} from 'vuelidate'
import {
  required
} from 'vuelidate/lib/validators'
import {
  baseUrl
} from '@/core/config/baseUrl'
import {
  icons
} from '@/core/config/icons'
export default {
  data() {
    return {
      formCarousel: {
        loading: false,
        data: {
          id: null,
          image: null,
          title: null,
          content: null,
          id_parent: null,
          type: 'CAROUSEL ITEM',
          child: {
            btn: {
              id: null,
              image: null,
              title: null,
              content: null,
              id_parent: null,
              type: 'BTN CAROUSEL ITEM',
            },
          }
        },
        deleteId: null
      },
      tableCarousel: {
        items: [],
        fields: [{
            key: 'no',
            label: 'No',
            sortable: false,
            class: 'w-5 text-center'
          },
          {
            key: 'title',
            label: 'Title',
            sortable: true,
            thClass: 'text-center',
            tdClass: ''
          },
          {
            key: 'content',
            label: 'Content',
            thClass: 'text-center',
            tdClass: ''
          },
          {
            key: 'action',
            label: 'Action',
            sortable: false,
            class: 'text-center w-10'
          }
        ],
        totalRows: 0,
        loading: false
      },
      pagingCarousel: {
        page: 1,
        perPage: 10,
        search: '',
        sortBy: 'post.title',
        sortDesc: false,
        sortDirection: 'asc',
        type: 'CAROUSEL ITEM'
      },
      optCarousel: {
        perPage: [10, 20, 30, 40, 50, 100, 200],
        icons: icons
      },
    }
  },
  mixins: [validationMixin],
  validations: {
    formCarousel: {
      data: {
        title: {
          required
        },
        content: {
          required
        },
        image: {
          required
        },
        child: {
          btn: {
            title: {
              required
            }
          }
        }
      }
    }
  },
  methods: {
    doLoad() {
      this.tableCarousel.loading = true
      let payload = this.pagingCarousel
      payload.sortDirection = this.pagingCarousel.sortDesc ? 'asc' : 'desc'
      let url = baseUrl + `/api/post`
      this.fetchData(url, 'POST', payload)
        .then(res => {
          if (res.status) {
            this.tableCarousel.items = res.data
            this.tableCarousel.totalRows = res.total
          } else {
            this.notif('Failed', res.msg, 'danger')
          }
          this.tableCarousel.loading = false
        })
        .catch(e => {
          this.tableCarousel.loading = false
          console.log(e)
          this.notif('Failed', 'List:Internal server error', 'danger')
        });
    },
    doUpdate(id) {
      let url = baseUrl + `/api/post/get`
      let data = {
        id: id
      }
      this.fetchData(url, 'POST', data)
        .then(res => {
          let resData = res.data
          this.formCarousel.data.id = resData.id
          this.formCarousel.data.image = resData.image
          this.formCarousel.data.title = resData.title
          this.formCarousel.data.content = resData.content
          this.formCarousel.data.id_parent = resData.id_parent
          this.formCarousel.data.type = 'CAROUSEL ITEM'
          resData.child.map((x) => {
            switch (x.type) {
              case 'BTN CAROUSEL ITEM':
                this.formCarousel.data.child.btn = x
                break;
            }
          })
          this.$bvModal.show('cm-modal-form-carousel')
        })
        .catch(e => {
          console.log(e)
          this.notif('Failed', 'Get:Internal server error', 'danger')
        });
    },
    doSave() {
      this.formCarousel.loading = true
      this.$v.formCarousel.data.$touch()
      if (this.$v.formCarousel.data.$invalid) {
        this.formCarousel.loading = false
        this.notif('Warning', 'Please complete required field', 'warning')
      } else {
        let url = baseUrl + '/api/post/create'
        if (this.formCarousel.data.id)
          url = baseUrl + '/api/post/update'
        this.fetchData(url, 'POST', this.formCarousel.data)
          .then(res => {
            if (res.status) {
              this.notif('Success', res.msg, 'success')
              this.clearForm()
              this.doLoad()
            } else {
              this.notif('Failed', res.msg, 'danger')
            }
            this.formCarousel.loading = false
          })
          .catch(e => {
            this.formCarousel.loading = false
            this.notif('Failed', 'Save:Internal server error', 'danger')
          });
      }
    },
    doDeleteCarousel(id, promt) {
      if (promt) {
        let url = baseUrl + `/api/post/delete`
        let data = {
          id: id
        }
        this.fetchData(url, 'POST', data)
          .then(res => {
            if (res.status) {
              this.notif('Success', res.msg, 'success')
              this.doLoad()
              this.$bvModal.hide('cm-modal-delete-carousel')
            } else {
              this.notif('Error', res.msg, 'danger')
              this.doLoad()
              this.$bvModal.hide('cm-modal-delete-carousel')
            }
          })
          .catch(e => {
            console.log(e)
            this.notif('Failed', 'Delete:Internal server error', 'danger')
          });
      } else {
        this.formCarousel.deleteId = id
        this.$bvModal.show('cm-modal-delete-carousel')
      }
    },
    fetchData(url, type, data) {
      let params = {
        method: type,
        headers: {
          'Content-Type': 'application/json',
        }
      }
      if (data)
        params.body = JSON.stringify(data)
      return fetch(
          url, params
        )
        .then(response => response.json())
    },
    previewImage(event, target) {
      let theImg = null
      let vm = this
      const inp = this.$refs.cm_form_img_carousel
      if (target == 'MAIN') {
        theImg = event.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(theImg);
        reader.onload = function () {
          vm.formCarousel.data.image = reader.result
          inp.type = 'text';
          inp.type = 'file';
        };
        reader.onerror = function () {
          vm.formCarousel.data.image = null
          inp.type = 'text';
          inp.type = 'file';
        };
      }
    },
    deleteImage(idx) {
      let id = this.formCarousel.data.child.image[idx].id
      if (id > 0) {
        this.formCarousel.data.child.image[idx].deleted = true
      } else {
        this.formCarousel.data.child.image.splice(idx, 1);
      }
    },
    clearForm() {
      this.$v.formCarousel.data.$reset()
      this.formCarousel.data = {
        id: null,
        image: null,
        title: null,
        content: null,
        id_parent: null,
        type: 'CAROUSEL ITEM',
        child: {
          btn: {
            id: null,
            image: null,
            title: 'BTN CAROUSEL ITEM',
            content: null,
            id_parent: null,
            type: 'BTN CAROUSEL ITEM',
          },
        }
      }
      this.formCarousel.deleteId = null
      this.$bvModal.hide('cm-modal-form-carousel')
    },
    notif(title, msg, type) {
      this.$bvToast.toast(msg, {
        title: title,
        autoHideDelay: 5000,
        variant: type,
        solid: true,
        toaster: 'b-toaster-bottom-left'
      })
    },
    thousand(num) {
      if (num) {
        let num_parts = num.toString().split(",");
        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return num_parts.join(",");
      } else {
        return 0
      }
    },
  },
  watch: {
    paging: {
      handler() {
        this.doLoad()
      },
      deep: true
    }
  },
  mounted() {
    this.doLoad()
    this.$root.$on('bv::modal::hide', () => {
      this.clearForm()
    })
  },
}
</script>

<style lang="scss">
.cm-delete-img {
  position: absolute;
  top: -10px;
  right: 0px;
  background: red;
  color: white;
  font-size: 20px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  transform: rotate(45deg);
  cursor: pointer;
}

.project-caption {
  padding: 10px;
  border: 1px dashed grey;

  h6,
  p {
    color: white;
    text-shadow: 1px 1px grey;
  }
}

.w-5 {
  width: 5%;
}

.w-10 {
  width: 10%;
}

.modal-dialog.modal-xxl {
  max-width: 90%;
}

.project-img {
  div[role="group"] {
    position: relative;
  }
}
</style>
