<template>
<div>
  <b-card class="card-custom gutter-b">
    <template #header>
      <b-row class="w-100 d-flex align-items-center" no-gutters>
        <b-col xs=6>
          <h5 class="m-0">Welcome Content</h5>
        </b-col>
        <b-col xs=6 class="text-right">
          <b-button variant="primary" v-b-modal.cm-modal-form-carousel>Add New</b-button>
        </b-col>
      </b-row>
    </template>
    <b-row>
      <b-col sm=12>
        <form @submit.prevent="doSaveWelcome()">
          <b-row>
            <b-col sm=6>
              <b-form-group label="Featured Image" description="Click to change/ add Image" class="project-img">
                <label for="cm_form_img_welcome" class="w-100" ref="previewImage">
                  <b-img-lazy :src="(formWelcome.data.image) ? formWelcome.data.image : require('@/assets/images/default.png')" fluid class="w-100" />
                  <input type="file" ref="cm_form_img_welcome" hidden id="cm_form_img_welcome" @change="previewImage($event,'MAIN')" accept="image/*">
                </label>
              </b-form-group>
            </b-col>
            <b-col sm=6>
              <b-form-group label="Title" :state="!$v.formWelcome.data.title.$error">
                <b-input v-model="formWelcome.data.title" />
              </b-form-group>
              <b-form-group label="Description" :state="!$v.formWelcome.data.content.$error">
                <wysiwyg v-model="formWelcome.data.content" />
              </b-form-group>
              <b-form-group label="Button Text" :state="!$v.formWelcome.data.child.btn.title.$error">
                <b-input v-model="formWelcome.data.child.btn.title" />
              </b-form-group>
              <b-form-group label="Button Link">
                <b-input v-model="formWelcome.data.child.btn.content" />
              </b-form-group>
            </b-col>
          </b-row>
          <div class="d-flex py-3 border-top justify-content-end">
            <b-button variant="light" @click="$bvModal.hide('cm-modal-form-carousel')">Close</b-button>
            <b-button type="submit" :disabled="formWelcome.loading" variant="primary" class="ml-3">
              <b-spinner small v-show="formWelcome.loading" /> Save
            </b-button>
          </div>
        </form>
      </b-col>
    </b-row>
  </b-card>
</div>
</template>

<script>
import {
  validationMixin
} from 'vuelidate'
import {
  required
} from 'vuelidate/lib/validators'
import {
  baseUrl
} from '@/core/config/baseUrl'
import {
  icons
} from '@/core/config/icons'
export default {
  data() {
    return {
      formWelcome: {
        loading: false,
        data: {
          id: null,
          image: null,
          title: null,
          content: null,
          id_parent: null,
          type: 'WELCOME ITEM',
          child: {
            btn: {
              id: null,
              image: null,
              title: null,
              content: null,
              id_parent: null,
              type: 'BTN WELCOME ITEM',
            },
          }
        },
        deleteId: null
      },
    }
  },
  mixins: [validationMixin],
  validations: {
    formWelcome: {
      data: {
        title: {
          required
        },
        content: {
          required
        },
        image: {
          required
        },
        child: {
          btn: {
            title: {
              required
            }
          }
        }
      }
    }
  },
  methods: {
    doLoadWelcome() {
      let payload = {
        page: 1,
        perPage: '~',
        search: '',
        sortBy: 'post.title',
        sortDesc: false,
        sortDirection: 'desc',
        type: 'WELCOME ITEM'
      }
      let url = baseUrl + `/api/post`
      this.fetchData(url, 'POST', payload)
        .then(res => {
          if (res.status) {
            if(res.data.length > 0){
              let resData = res.data[0]
              this.formWelcome.data.id = resData.id
              this.formWelcome.data.image = resData.image
              this.formWelcome.data.title = resData.title
              this.formWelcome.data.content = resData.content
              this.formWelcome.data.id_parent = resData.id_parent
              this.formWelcome.data.type = 'WELCOME ITEM'
              resData.child.map((x) => {
                switch (x.type) {
                  case 'BTN WELCOME ITEM':
                    this.formWelcome.data.child.btn = x
                    break;
                }
              })
            }
          } else {
            this.notif('Failed', res.msg, 'danger')
          }
        })
        .catch(e => {
          console.log(e)
          this.notif('Failed', 'List:Internal server error', 'danger')
        });
    },
    doSaveWelcome() {
      this.formWelcome.loading = true
      this.$v.formWelcome.data.$touch()
      if (this.$v.formWelcome.data.$invalid) {
        this.formWelcome.loading = false
        this.notif('Warning', 'Please complete required field', 'warning')
      } else {
        let url = baseUrl + '/api/post/create'
        if (this.formWelcome.data.id)
          url = baseUrl + '/api/post/update'
        this.fetchData(url, 'POST', this.formWelcome.data)
          .then(res => {
            if (res.status) {
              this.notif('Success', res.msg, 'success')
              this.doLoadWelcome()
            } else {
              this.notif('Failed', res.msg, 'danger')
            }
            this.formWelcome.loading = false
          })
          .catch(e => {
            this.formWelcome.loading = false
            this.notif('Failed', 'Save:Internal server error', 'danger')
          });
      }
    },
    fetchData(url, type, data) {
      let params = {
        method: type,
        headers: {
          'Content-Type': 'application/json',
        }
      }
      if (data)
        params.body = JSON.stringify(data)
      return fetch(
          url, params
        )
        .then(response => response.json())
    },
    previewImage(event, target) {
      let theImg = null
      let vm = this
      const inp = this.$refs.cm_form_img_welcome
      if (target == 'MAIN') {
        theImg = event.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(theImg);
        reader.onload = function () {
          vm.formWelcome.data.image = reader.result
          inp.type = 'text';
          inp.type = 'file';
        };
        reader.onerror = function () {
          vm.formWelcome.data.image = null
          inp.type = 'text';
          inp.type = 'file';
        };
      }
    },
    deleteImage(idx) {
      let id = this.formWelcome.data.child.image[idx].id
      if (id > 0) {
        this.formWelcome.data.child.image[idx].deleted = true
      } else {
        this.formWelcome.data.child.image.splice(idx, 1);
      }
    },
    notif(title, msg, type) {
      this.$bvToast.toast(msg, {
        title: title,
        autoHideDelay: 5000,
        variant: type,
        solid: true,
        toaster: 'b-toaster-bottom-left'
      })
    },
    thousand(num) {
      if (num) {
        let num_parts = num.toString().split(",");
        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return num_parts.join(",");
      } else {
        return 0
      }
    },
  },
  watch: {
    paging: {
      handler() {
        this.doLoadWelcome()
      },
      deep: true
    }
  },
  mounted() {
    this.doLoadWelcome()
  },
}
</script>

<style lang="scss">
.cm-delete-img {
  position: absolute;
  top: -10px;
  right: 0px;
  background: red;
  color: white;
  font-size: 20px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  transform: rotate(45deg);
  cursor: pointer;
}

.project-caption {
  padding: 10px;
  border: 1px dashed grey;

  h6,
  p {
    color: white;
    text-shadow: 1px 1px grey;
  }
}

.w-5 {
  width: 5%;
}

.w-10 {
  width: 10%;
}

.modal-dialog.modal-xxl {
  max-width: 90%;
}

.project-img {
  div[role="group"] {
    position: relative;
  }
}
</style>
