<template>
<div>
  <Carousel/>
  <Welcome/>
  <Testimonial/>
  <HotelInfo/>
  <!-- <BookOnline/> -->
</div>
</template>

<script>
import {
  SET_BREADCRUMB
} from "@/core/services/store/breadcrumbs.module";
import Carousel from '@/page/PageContent/Home/Carousel'
import Welcome from '@/page/PageContent/Home/Welcome'
import Testimonial from '@/page/PageContent/Home/Testimonial'
import HotelInfo from '@/page/PageContent/Home/HotelInfo'
import BookOnline from '@/page/PageContent/Home/BookOnline'
export default {
  components: {
    Carousel, Welcome, Testimonial, HotelInfo, BookOnline
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "Home"
    }])
  },
}
</script>
